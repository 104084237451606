import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Context as UserContext } from '../../context/UserContext';
import { Context as SpecialOfferContext } from '../../context/SpecialOfferContext';
import GalleryItem from '../../components/GalleryItem/GalleryItem';
import ChipsList from '../../components/ChipsList/ChipsList';
import Error from '../../components/Error/Error';
import { Paper } from '@material-ui/core';
import specialOfferTemplates from '../../utils/specialOfferTemplates';
import ProductsList from '../../components/ProductsList/ProductsList';
import ImageInput from '../../components/ImageInput/ImageInput';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(3),
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  products: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '& $textField': {
      marginRight: theme.spacing(2),
      marginBottom: 0,
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
  },
  productRow: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  iconsGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  productsIcon: {
    alignSelf: 'center',
    marginLeft: theme.spacing(1),
  },
  pageActionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
  featureRow: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
    '& $textField': {
      marginBottom: theme.spacing(2),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
}));

export default function SpecialOfferPage({ onRender }) {
  // VARS
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();

  // STATE
  const [name, setName] = useState('');
  const [headline, setHeadline] = useState('');
  const [productName, setProductName] = useState('');
  const [gallery, setGallery] = useState([]);

  // CONTEXTS
  const {
    state: { user },
  } = useContext(UserContext);

  const {
    state: { specialOffer, loading: specialOfferLoading, error: specialOfferError },
    loadSpecialOffer,
    updateSpecialOffer,
    createSpecialOffer,
  } = useContext(SpecialOfferContext);

  // EFFECTS
  useEffect(() => {
    if (user) {
      loadSpecialOffer(id);
    }
    return () => {
      // Reset state when the component unmounts or when navigating to a new offer
      setName('');
      setHeadline('');
      setProductName('');
      setGallery([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, id]);

  useEffect(() => {
    if (specialOffer) {
      onRender(`Special offer - ${specialOffer.name}`);
      // Set values after special offer is loaded
      setName(specialOffer.name);
      setHeadline(specialOffer.headline);
      setProductName(specialOffer.productName);
      setGallery(specialOffer.gallery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialOffer]);

  if (specialOfferError) {
    return <Error error={specialOfferError} />;
  }

  if (!specialOffer) {
    return null;
  }

  function handleSpecialOfferUpdate(update) {
    updateSpecialOffer(id, update);
  }

  function renderGallery() {
    function handleAddGalleryItem() {
      handleSpecialOfferUpdate({
        gallery: [
          ...gallery,
          {
            source: '',
            thumbnail: '',
            type: 'image',
          },
        ],
      });
    }

    function handleUpdateGalleryItem(update, index) {
      const newGallery = [...gallery];
      const entries = Object.entries(update)[0];

      newGallery[index][entries[0]] = entries[1];
      setGallery(newGallery);
      handleSpecialOfferUpdate({ gallery: newGallery });
    }

    function handleDeleteGalleryItem(itemIndex) {
      handleSpecialOfferUpdate({
        gallery: gallery.filter((item, index) => index !== itemIndex),
      });
    }

    return (
      <>
        <Typography variant='h6' gutterBottom>
          Gallery
        </Typography>
        {gallery.map((item, index) => (
          <Box className={classes.products} key={index}>
            <GalleryItem
              key={`${item.source}-${index}`} // Ensure this key is unique for each item
              index={index}
              source={item.source}
              type={item.type}
              thumbnail={item.thumbnail}
              onUpdate={handleUpdateGalleryItem}
            />
            <Box className={classes.iconsGroup}>
              <IconButton
                color='secondary'
                aria-label='Delete'
                component='span'
                size='small'
                onClick={() => handleDeleteGalleryItem(index)}
                className={classes.productsIcon}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                color='primary'
                aria-label='Add'
                component='span'
                size='small'
                onClick={() => handleAddGalleryItem()}
                className={classes.productsIcon}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
        {!gallery.length && (
          <Box className={classes.iconsGroup}>
            <IconButton
              color='primary'
              aria-label='Add'
              component='span'
              size='small'
              onClick={() => handleAddGalleryItem()}
              className={classes.productsIcon}
            >
              <AddIcon />
            </IconButton>
          </Box>
        )}
      </>
    );
  }

  function renderPageActionButtons() {
    async function onCloneClick() {
      const clone = specialOffer;
      delete clone.createdAt;
      delete clone._id;
      delete clone.__v;
      clone.name += ' CLONE';

      try {
        const created = await createSpecialOffer(clone);
        history.push({});
        history.push(`/specialOffers/${created._id}`);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error: ', error);
      }
    }

    return (
      <Box className={classes.pageActionButtons}>
        <Button
          variant='contained'
          color='primary'
          endIcon={<ContentCopyIcon />}
          onClick={() => onCloneClick()}
        >
          Clone
        </Button>
      </Box>
    );
  }

  const Delivered = () => {
    const [value, setValue] = useState('');
    useEffect(() => {
      setValue(specialOffer.delivered);
    }, []);

    return (
      <TextField
        label='Delivered'
        helperText='Ex: 18,500'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() =>
          handleSpecialOfferUpdate({
            delivered: value,
          })
        }
        fullWidth
        className={classes.textField}
      />
    );
  };

  const Disclaimer = () => {
    const [value, setValue] = useState('');
    useEffect(() => {
      setValue(specialOffer.disclaimer);
    }, []);

    return (
      <TextField
        label='Disclaimer'
        value={value}
        multiline
        minRows={3}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() =>
          handleSpecialOfferUpdate({
            disclaimer: value,
          })
        }
        fullWidth
        className={classes.textField}
      />
    );
  };

  const ShippingText = () => {
    const [value, setValue] = useState('');

    useEffect(() => {
      setValue(specialOffer.shippingText);
    }, []);

    return (
      <TextField
        label='Shipping Text'
        value={value}
        multiline
        minRows={3}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() =>
          handleSpecialOfferUpdate({
            shippingText: value,
          })
        }
        fullWidth
        className={classes.textField}
      />
    );
  };

  const CtaText = () => {
    const [value, setValue] = useState('');

    useEffect(() => {
      setValue(specialOffer.ctaText);
    }, []);

    return (
      <TextField
        label='Cta text'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        helperText='If empty, the default text will be used.'
        onBlur={() =>
          handleSpecialOfferUpdate({
            ctaText: value,
          })
        }
        fullWidth
        className={classes.textField}
      />
    );
  };

  const SubHeadline = () => {
    const [value, setValue] = useState('');

    useEffect(() => {
      setValue(specialOffer.subHeadline);
    }, []);

    return (
      <TextField
        label='Subheadline'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() =>
          handleSpecialOfferUpdate({
            subHeadline: value,
          })
        }
        fullWidth
        className={classes.textField}
      />
    );
  };

  const Description = () => {
    const [value, setValue] = useState('');

    useEffect(() => {
      setValue(specialOffer.description);
    }, []);

    return (
      <TextField
        label='Description'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() =>
          handleSpecialOfferUpdate({
            description: value,
          })
        }
        fullWidth
        className={classes.textField}
      />
    );
  };

  const ProductImage = () => {
    const [value, setValue] = useState('');

    useEffect(() => {
      setValue(specialOffer.productImage);
    }, []);

    return (
      <ImageInput
        label='Product Image'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() =>
          handleSpecialOfferUpdate({
            productImage: value,
          })
        }
        fullWidth
        className={classes.textField}
      />
    );
  };

  const Features = () => {
    const [features, setFeatures] = useState([]);

    useEffect(() => {
      // Initialize features from specialOffer data
      setFeatures(specialOffer.features || []);
    }, []);

    function handleAddFeature() {
      const newFeatures = [
        ...features,
        {
          title: '',
          description: '',
          icon: '',
        },
      ];
      setFeatures(newFeatures);
      handleSpecialOfferUpdate({ features: newFeatures });
    }

    function handleUpdateFeature(index, field, value) {
      const newFeatures = [...features];
      newFeatures[index][field] = value;
      setFeatures(newFeatures);
    }

    function handleDeleteFeature(indexToDelete) {
      const newFeatures = features.filter((_, index) => index !== indexToDelete);
      setFeatures(newFeatures);
      handleSpecialOfferUpdate({ features: newFeatures });
    }

    function handleBlur() {
      handleSpecialOfferUpdate({ features });
    }

    return (
      <>
        <Typography variant='h6' gutterBottom>
          Features
        </Typography>
        {features.map((feature, index) => (
          <Box className={classes.products} key={index} style={{ border: '2px dotted #ccc' }}>
            <Box style={{ width: '100%' }} className={classes.featureRow}>
              <TextField
                label='Title'
                value={feature.title || ''}
                onChange={(e) => handleUpdateFeature(index, 'title', e.target.value)}
                onBlur={handleBlur}
                fullWidth
                className={classes.textField}
              />
              <TextField
                label='Description'
                value={feature.description || ''}
                onChange={(e) => handleUpdateFeature(index, 'description', e.target.value)}
                onBlur={handleBlur}
                fullWidth
                className={classes.textField}
              />
              <TextField
                label='Icon'
                value={feature.icon || ''}
                onChange={(e) => handleUpdateFeature(index, 'icon', e.target.value)}
                onBlur={handleBlur}
                fullWidth
                className={classes.textField}
              />
            </Box>
            <Box className={classes.iconsGroup}>
              <IconButton
                color='secondary'
                aria-label='Delete'
                component='span'
                size='small'
                onClick={() => handleDeleteFeature(index)}
                className={classes.productsIcon}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                color='primary'
                aria-label='Add'
                component='span'
                size='small'
                onClick={handleAddFeature}
                className={classes.productsIcon}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
        {!features.length && (
          <Box className={classes.iconsGroup}>
            <IconButton
              color='primary'
              aria-label='Add'
              component='span'
              size='small'
              onClick={handleAddFeature}
              className={classes.productsIcon}
            >
              <AddIcon />
            </IconButton>
          </Box>
        )}
      </>
    );
  };

  const Testimonials = () => {
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
      // Initialize testimonials from specialOffer data
      setTestimonials(specialOffer.testimonials || []);
    }, []);

    function handleAddTestimonial() {
      const newTestimonials = [
        ...testimonials,
        {
          text: '',
          author: '',
          stars: 5,
        },
      ];
      setTestimonials(newTestimonials);
      handleSpecialOfferUpdate({ testimonials: newTestimonials });
    }

    function handleUpdateTestimonial(index, field, value) {
      const newTestimonials = [...testimonials];
      newTestimonials[index][field] = value;
      setTestimonials(newTestimonials);
    }

    function handleDeleteTestimonial(indexToDelete) {
      const newTestimonials = testimonials.filter((_, index) => index !== indexToDelete);
      setTestimonials(newTestimonials);
      handleSpecialOfferUpdate({ testimonials: newTestimonials });
    }

    function handleBlur() {
      handleSpecialOfferUpdate({ testimonials });
    }

    return (
      <>
        <Typography variant='h6' gutterBottom>
          Testimonials
        </Typography>
        {testimonials.map((testimonial, index) => (
          <Box className={classes.products} key={index} style={{ border: '2px dotted #ccc' }}>
            <Box style={{ width: '100%' }} className={classes.featureRow}>
              <TextField
                label='Text'
                value={testimonial.text || ''}
                onChange={(e) => handleUpdateTestimonial(index, 'text', e.target.value)}
                onBlur={handleBlur}
                fullWidth
                multiline
                minRows={3}
                className={classes.textField}
              />
              <TextField
                label='Author'
                value={testimonial.author || ''}
                onChange={(e) => handleUpdateTestimonial(index, 'author', e.target.value)}
                onBlur={handleBlur}
                fullWidth
                className={classes.textField}
              />
              <TextField
                label='Stars'
                value={testimonial.stars}
                onChange={(e) => handleUpdateTestimonial(index, 'stars', e.target.value)}
                onBlur={handleBlur}
                fullWidth
                className={classes.textField}
                helperText='Value from 1 to 5, can use half stars (e.g. 4.5)'
              />
            </Box>
            <Box className={classes.iconsGroup}>
              <IconButton
                color='secondary'
                aria-label='Delete'
                component='span'
                size='small'
                onClick={() => handleDeleteTestimonial(index)}
                className={classes.productsIcon}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                color='primary'
                aria-label='Add'
                component='span'
                size='small'
                onClick={handleAddTestimonial}
                className={classes.productsIcon}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
        {!testimonials.length && (
          <Box className={classes.iconsGroup}>
            <IconButton
              color='primary'
              aria-label='Add'
              component='span'
              size='small'
              onClick={handleAddTestimonial}
              className={classes.productsIcon}
            >
              <AddIcon />
            </IconButton>
          </Box>
        )}
      </>
    );
  };

  const Benefits = () => {
    const [benefitItems, setBenefitItems] = useState([]);

    useEffect(() => {
      // Initialize benefits from specialOffer data
      setBenefitItems(specialOffer.benefitItems || []);
    }, []);

    function handleAddBenefit() {
      const newBenefits = [
        ...benefitItems,
        {
          title: '',
          text: '',
          image: '',
        },
      ];
      setBenefitItems(newBenefits);
      handleSpecialOfferUpdate({ benefitItems: newBenefits });
    }

    function handleUpdateBenefit(index, field, value) {
      const newBenefits = [...benefitItems];
      newBenefits[index][field] = value;
      setBenefitItems(newBenefits);
    }

    function handleDeleteBenefit(indexToDelete) {
      const newBenefits = benefitItems.filter((_, index) => index !== indexToDelete);
      setBenefitItems(newBenefits);
      handleSpecialOfferUpdate({ benefitItems: newBenefits });
    }

    function handleBlur() {
      handleSpecialOfferUpdate({ benefitItems });
    }

    return (
      <>
        <Typography variant='h6' gutterBottom>
          Benefits
        </Typography>
        {benefitItems.map((benefit, index) => (
          <Box className={classes.products} key={index} style={{ border: '2px dotted #ccc' }}>
            <Box style={{ width: '100%' }} className={classes.featureRow}>
              <TextField
                label='Title'
                value={benefit.title || ''}
                onChange={(e) => handleUpdateBenefit(index, 'title', e.target.value)}
                onBlur={handleBlur}
                fullWidth
                className={classes.textField}
              />
              <TextField
                label='Text'
                value={benefit.text || ''}
                onChange={(e) => handleUpdateBenefit(index, 'text', e.target.value)}
                onBlur={handleBlur}
                fullWidth
                multiline
                minRows={3}
                className={classes.textField}
              />
              <ImageInput
                label='Image'
                value={benefit.image || ''}
                onChange={(e) => handleUpdateBenefit(index, 'image', e.target.value)}
                onBlur={handleBlur}
                fullWidth
                className={classes.textField}
              />
            </Box>
            <Box className={classes.iconsGroup}>
              <IconButton
                color='secondary'
                aria-label='Delete'
                component='span'
                size='small'
                onClick={() => handleDeleteBenefit(index)}
                className={classes.productsIcon}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                color='primary'
                aria-label='Add'
                component='span'
                size='small'
                onClick={handleAddBenefit}
                className={classes.productsIcon}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
        {!benefitItems.length && (
          <Box className={classes.iconsGroup}>
            <IconButton
              color='primary'
              aria-label='Add'
              component='span'
              size='small'
              onClick={handleAddBenefit}
              className={classes.productsIcon}
            >
              <AddIcon />
            </IconButton>
          </Box>
        )}
      </>
    );
  };

  const Promotion = () => {
    const [promotion, setPromotion] = useState({
      title: '',
      offerTitle: '',
      leftProduct: {
        image: '',
        title: '',
      },
      rightProduct: {
        image: '',
        title: '',
        subtitle: '',
      },
      bullets: [],
    });

    useEffect(() => {
      // Initialize promotion from specialOffer data
      if (specialOffer.promotion) {
        setPromotion(specialOffer.promotion);
      }
    }, []);

    function handleUpdatePromotion(field, value) {
      const newPromotion = { ...promotion };

      // Handle nested fields with dot notation (e.g., "leftProduct.image")
      if (field.includes('.')) {
        const [parent, child] = field.split('.');
        newPromotion[parent][child] = value;
      } else {
        newPromotion[field] = value;
      }

      setPromotion(newPromotion);
    }

    function handleAddBullet() {
      const newBullets = [...promotion.bullets, ''];
      handleUpdatePromotion('bullets', newBullets);
    }

    function handleUpdateBullet(index, value) {
      const newBullets = [...promotion.bullets];
      newBullets[index] = value;
      handleUpdatePromotion('bullets', newBullets);
    }

    function handleDeleteBullet(indexToDelete) {
      const newBullets = promotion.bullets.filter((_, index) => index !== indexToDelete);
      handleUpdatePromotion('bullets', newBullets);
    }

    function handleBlur() {
      handleSpecialOfferUpdate({ promotion });
    }

    return (
      <>
        <Typography variant='h6' gutterBottom>
          Promotion Section
        </Typography>
        <Paper className={classes.paper} style={{ marginBottom: '16px' }}>
          <Typography variant='subtitle1' gutterBottom>
            Main Settings
          </Typography>
          <TextField
            label='Title'
            value={promotion.title || ''}
            onChange={(e) => handleUpdatePromotion('title', e.target.value)}
            onBlur={handleBlur}
            fullWidth
            className={classes.textField}
            helperText="Example: Don't Miss Out, Sweat Toxins Out!"
          />
          <TextField
            label='Offer Title'
            value={promotion.offerTitle || ''}
            onChange={(e) => handleUpdatePromotion('offerTitle', e.target.value)}
            onBlur={handleBlur}
            fullWidth
            className={classes.textField}
            helperText='Example: Unlock the Perfect Match'
          />
        </Paper>

        <Paper className={classes.paper} style={{ marginBottom: '16px' }}>
          <Typography variant='subtitle1' gutterBottom>
            Left Product
          </Typography>
          <ImageInput
            label='Image'
            value={promotion.leftProduct.image || ''}
            onChange={(e) => handleUpdatePromotion('leftProduct.image', e.target.value)}
            onBlur={handleBlur}
            fullWidth
            className={classes.textField}
          />
          <TextField
            label='Title'
            value={promotion.leftProduct.title || ''}
            onChange={(e) => handleUpdatePromotion('leftProduct.title', e.target.value)}
            onBlur={handleBlur}
            fullWidth
            className={classes.textField}
          />
        </Paper>

        <Paper className={classes.paper} style={{ marginBottom: '16px' }}>
          <Typography variant='subtitle1' gutterBottom>
            Right Product
          </Typography>
          <ImageInput
            label='Image'
            value={promotion.rightProduct.image || ''}
            onChange={(e) => handleUpdatePromotion('rightProduct.image', e.target.value)}
            onBlur={handleBlur}
            fullWidth
            className={classes.textField}
          />
          <TextField
            label='Title'
            value={promotion.rightProduct.title || ''}
            onChange={(e) => handleUpdatePromotion('rightProduct.title', e.target.value)}
            onBlur={handleBlur}
            fullWidth
            className={classes.textField}
          />
          <TextField
            label='Subtitle'
            value={promotion.rightProduct.subtitle || ''}
            onChange={(e) => handleUpdatePromotion('rightProduct.subtitle', e.target.value)}
            onBlur={handleBlur}
            fullWidth
            className={classes.textField}
            helperText='Example: Perfect Match!'
          />
        </Paper>

        <Paper className={classes.paper}>
          <Typography variant='subtitle1' gutterBottom>
            Bullet Points
          </Typography>
          {promotion.bullets.map((bullet, index) => (
            <Box key={index} className={classes.productRow} style={{ alignItems: 'center' }}>
              <TextField
                label={`Bullet ${index + 1}`}
                value={bullet || ''}
                onChange={(e) => handleUpdateBullet(index, e.target.value)}
                onBlur={handleBlur}
                fullWidth
                className={classes.textField}
                style={{ marginBottom: 0 }}
              />
              <Box className={classes.iconsGroup}>
                <IconButton
                  color='secondary'
                  aria-label='Delete'
                  component='span'
                  size='small'
                  onClick={() => handleDeleteBullet(index)}
                  className={classes.productsIcon}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
          <Button
            variant='outlined'
            color='primary'
            startIcon={<AddIcon />}
            onClick={handleAddBullet}
            style={{ marginTop: '8px' }}
          >
            Add Bullet Point
          </Button>
        </Paper>
      </>
    );
  };

  const IsUpgrade = () => {
    const [value, setValue] = useState(false);

    useEffect(() => {
      setValue(specialOffer.isUpgrade);
    }, []);

    return (
      <FormControl fullWidth className={classes.textField}>
        <InputLabel>Is Upgrade</InputLabel>
        <Select
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            handleSpecialOfferUpdate({
              isUpgrade: e.target.value,
            });
          }}
        >
          <MenuItem value={true}>Yes</MenuItem>
          <MenuItem value={false}>No</MenuItem>
        </Select>
      </FormControl>
    );
  };

  return (
    <Container maxWidth='lg' className={classes.container}>
      {specialOfferLoading && <LinearProgress className={classes.progress} />}
      {renderPageActionButtons()}
      <Paper className={classes.paper}>
        <TextField
          label='Id'
          value={specialOffer._id}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          className={classes.textField}
        />
        <TextField
          label='Special Offer Name'
          helperText='Used for internal purposes.'
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={() =>
            handleSpecialOfferUpdate({
              name,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <FormControl fullWidth className={classes.textField}>
          <InputLabel>Template</InputLabel>
          <Select
            value={specialOffer.template.id}
            label='Template'
            onChange={(e) => {
              handleSpecialOfferUpdate({
                template: {
                  id: e.target.value,
                  name: specialOfferTemplates[e.target.value],
                },
              });
            }}
          >
            {specialOfferTemplates.map((name, index) => (
              <MenuItem key={index} value={index}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label='Product Name'
          value={productName}
          helperText='Used on the client facing special offer page.'
          onChange={(e) => setProductName(e.target.value)}
          onBlur={() =>
            handleSpecialOfferUpdate({
              productName,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <IsUpgrade />
        <TextField
          label='Headline'
          value={headline}
          onChange={(e) => setHeadline(e.target.value)}
          onBlur={() =>
            handleSpecialOfferUpdate({
              headline,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <SubHeadline />
        <Description />
        <ProductImage />
        <Delivered />
        <Disclaimer />
        <ShippingText />
        <CtaText />
        <ChipsList
          items={specialOffer.productIds}
          onUpdate={(newItems) => handleSpecialOfferUpdate({ productIds: newItems })}
          title='Product Ids'
        />
        <FormControl fullWidth className={classes.textField}>
          <InputLabel>Products sorting</InputLabel>
          <Select
            value={specialOffer.productsSorting}
            onChange={(e) => {
              handleSpecialOfferUpdate({
                productsSorting: e.target.value,
              });
            }}
          >
            <MenuItem value={'asc'}>Ascending</MenuItem>
            <MenuItem value={'desc'}>Descending</MenuItem>
          </Select>
        </FormControl>
        {specialOffer.productIds.length > 0 && (
          <ProductsList
            offer={specialOffer}
            updateFunction={updateSpecialOffer}
            onSyncClick={() => handleSpecialOfferUpdate({ productIds: specialOffer.productIds })}
          />
        )}
        <ChipsList
          items={specialOffer.benefits}
          onUpdate={(newItems) => handleSpecialOfferUpdate({ benefits: newItems })}
          title='Benefit Tags'
        />
        <Features />
        <Testimonials />
        <Benefits />
        <Promotion />
        {renderGallery()}
      </Paper>
    </Container>
  );
}

SpecialOfferPage.propTypes = {
  onRender: PropTypes.func,
};
