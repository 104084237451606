/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Container,
  LinearProgress,
  IconButton,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Tab,
  Tabs,
} from '@material-ui/core';
import { ContentCopy as ContentCopyIcon, Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';

import { Context as OfferContext } from '../../context/OfferContext';
import { Context as UserContext } from '../../context/UserContext';
import { Context as SpecialOffersContext } from '../../context/SpecialOffersContext';
import { Context as FreeProductsContext } from '../../context/FreeProductsContext';
import { Context as CrossSellsContext } from '../../context/CrossSellsContext';
import { Context as ReviewsContext } from '../../context/ReviewsContext';

import ImageInput from '../../components/ImageInput/ImageInput';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';
import ChipsList from '../../components/ChipsList/ChipsList';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import ProductsList from '../../components/ProductsList/ProductsList';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  textField: {
    marginBottom: theme.spacing(3),
  },
  products: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '& $textField': {
      marginRight: theme.spacing(2),
      marginBottom: 0,
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
  },
  productRow: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  iconsGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  productsIcon: {
    alignSelf: 'center',
    marginLeft: theme.spacing(1),
  },
  specialOffers: {
    marginBottom: theme.spacing(3),
  },
  bannerDatePicker: {
    marginBottom: theme.spacing(2),
  },
  freeProducts: {
    marginBottom: theme.spacing(3),
  },
  crossSells: {
    marginBottom: theme.spacing(3),
  },
  switch: {
    marginTop: theme.spacing(2),
  },
  reviews: {
    marginBottom: theme.spacing(3),
  },
  fbPixelPair: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  fbPixelId: {
    flex: 1,
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  fbPixelToken: {
    flex: 3,
  },
  fbPixelIcons: {
    display: 'flex',
    justifyContent: 'center',
  },
  fbPixelIcon: {
    alignSelf: 'center',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  pageActionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
}));

export default function OfferPage({ onRender }) {
  // VARS
  const classes = useStyles();
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // CONTEXTS
  const {
    state: { user },
  } = useContext(UserContext);

  const {
    state: { specialOffers, loading: specialOffersLoading, error: specialOffersError },
    loadSpecialOffers,
  } = useContext(SpecialOffersContext);

  const {
    state: { reviews, loading: reviewsLoading, error: reviewsError },
    loadReviews,
  } = useContext(ReviewsContext);

  const {
    state: { freeProducts, loading: freeProductsLoading, error: freeProductsError },
    loadFreeProducts,
  } = useContext(FreeProductsContext);

  const {
    state: { crossSells, loading: crossSellsLoading, error: crossSellsError },
    loadCrossSells,
  } = useContext(CrossSellsContext);

  const {
    state: { offer, loading: offerLoading, error: offerError },
    loadOffer,
    updateOffer,
    createOffer,
  } = useContext(OfferContext);

  useEffect(() => {
    if (user) {
      loadOffer(id);
      loadSpecialOffers();
      loadFreeProducts();
      loadReviews();
      loadCrossSells();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    onRender(`Offer - ${offer?.name}`);
  }, [offer]);

  if (offerError || specialOffersError || freeProductsError || reviewsError || crossSellsError) {
    return (
      <Error
        error={offerError || specialOffersError || freeProductsError || reviewsError || crossSellsError}
      />
    );
  }

  if (specialOffersLoading || freeProductsLoading || reviewsLoading || crossSellsLoading) {
    return <Loading />;
  }

  if (!offer) {
    return null;
  }

  function handleOfferUpdate(update) {
    updateOffer(id, update);
  }

  const PreloaderSettings = () => {
    const [preloader, setPreloader] = useState({
      active: false,
      discount: '',
      review: {
        author: '',
        text: '',
      },
    });

    useEffect(() => {
      setPreloader(offer.preloader);
    }, [offer.preloader]);

    function handleDiscountChange(value) {
      setPreloader({
        ...preloader,
        discount: value,
      });
    }

    function handleReviewChange(field, value) {
      setPreloader({
        ...preloader,
        review: {
          ...preloader.review,
          [field]: value,
        },
      });
    }

    return (
      <>
        <Typography variant='h6' gutterBottom>
          Preloader
        </Typography>
        <FormControlLabel
          className={classes.textField}
          control={
            <Checkbox
              checked={offer.preloader.active}
              onChange={(e) => {
                handleOfferUpdate({
                  preloader: {
                    ...preloader,
                    active: e.target.checked,
                  },
                });
              }}
              color='primary'
            />
          }
          label='Active'
        />
        <TextField
          label='Discount'
          helperText='Example: 50%'
          value={preloader.discount}
          onChange={(e) => handleDiscountChange(e.target.value)}
          onBlur={(e) =>
            handleOfferUpdate({
              preloader,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <TextField
          label='Review author'
          value={preloader.review.author}
          onChange={(e) => handleReviewChange('author', e.target.value)}
          onBlur={() =>
            handleOfferUpdate({
              preloader,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <TextField
          label='Review text'
          value={preloader.review.text}
          onChange={(e) => handleReviewChange('text', e.target.value)}
          onBlur={() =>
            handleOfferUpdate({
              preloader,
            })
          }
          fullWidth
          className={classes.textField}
        />
      </>
    );
  };

  const CompanySettings = () => {
    const [company, setCompany] = useState({
      name: '',
      email: '',
      phone: '',
      address: '',
      website: '',
    });

    useEffect(() => {
      setCompany(offer.company);
    }, [offer]);

    function handleCompanyChange(field, value) {
      setCompany({
        ...company,
        [field]: value,
      });
    }

    return (
      <>
        <Typography variant='h6' gutterBottom>
          Company Details
        </Typography>
        <TextField
          label='Legal Name'
          value={company.name}
          onChange={(e) => handleCompanyChange('name', e.target.value)}
          onBlur={() =>
            handleOfferUpdate({
              company,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <TextField
          label='Email'
          value={company.email}
          onChange={(e) => handleCompanyChange('email', e.target.value)}
          onBlur={() =>
            handleOfferUpdate({
              company,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <TextField
          label='Website'
          value={company.website}
          onChange={(e) => handleCompanyChange('website', e.target.value)}
          onBlur={() =>
            handleOfferUpdate({
              company,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <TextField
          label='Phone'
          value={company.phone}
          onChange={(e) => handleCompanyChange('phone', e.target.value)}
          onBlur={() =>
            handleOfferUpdate({
              company,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <TextField
          label='Address'
          value={company.address}
          onChange={(e) => handleCompanyChange('address', e.target.value)}
          onBlur={() =>
            handleOfferUpdate({
              company,
            })
          }
          fullWidth
          className={classes.textField}
        />
      </>
    );
  };

  const UpsellSettings = () => {
    const [state, setState] = useState({
      countdown: true,
      title: 'HURRY UP! LIMITED QUANTITY AVAILABLE',
      subtitle: 'Congratulations! Your order is eligible for a free gift! Pick one from the options below.',
      terms: `This exclusive offer is only available to new WebTV customers. Included is 1 month of free WebTV
			services and fitness video library, and will subscribe at a monthly discounted rate thereafter of
			$19.97. Cancel anytime by calling ${offer.company.phone} or by emailing ${offer.company.email}`,
      template: 'freeProducts',
      oneProductSettings: {
        imgUrl: '',
        billingModelId: '',
        offerId: '',
        stickyId: '',
        productName: '',
        isFreeProduct: true,
      },
      lastChancePage: {
        title: 'Last Chance! Claim Your Free Gift In',
        subtitle: "Reminder: This is your last chance to claim your free gift. Don't miss out!",
        countdown: true,
      },
    });

    useEffect(() => {
      // Load existing upsell settings from the offer, if available
      if (offer.upsellSettings) {
        let updatedTerms = offer.upsellSettings.terms;
        if (offer.company) {
          updatedTerms = updatedTerms
            .replace('{{companyPhone}}', offer.company.phone)
            .replace('{{companyEmail}}', offer.company.email);
        }
        setState({ ...offer.upsellSettings, terms: updatedTerms });
      }
    }, [offer]);

    const handleFieldChange = (field, value, subfield = null) => {
      if (subfield) {
        setState((prevState) => ({
          ...prevState,
          [field]: {
            ...prevState[field],
            [subfield]: value,
          },
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          [field]: value,
        }));
      }
    };

    const renderOneProductSettings = () => {
      const { oneProductSettings } = state;

      return (
        <>
          <TextField
            label='Billing Model ID'
            className={classes.textField}
            value={oneProductSettings.billingModelId}
            onChange={(e) => handleFieldChange('oneProductSettings', e.target.value, 'billingModelId')}
            onBlur={() => handleOfferUpdate({ upsellSettings: state })}
            fullWidth
          />
          <TextField
            label='Offer ID'
            className={classes.textField}
            value={oneProductSettings.offerId}
            onChange={(e) => handleFieldChange('oneProductSettings', e.target.value, 'offerId')}
            onBlur={() => handleOfferUpdate({ upsellSettings: state })}
            fullWidth
          />
          <TextField
            label='Sticky PID'
            className={classes.textField}
            value={oneProductSettings.stickyId}
            onChange={(e) => handleFieldChange('oneProductSettings', e.target.value, 'stickyId')}
            onBlur={() => handleOfferUpdate({ upsellSettings: state })}
            fullWidth
          />
          <TextField
            label='Product Name'
            className={classes.textField}
            value={oneProductSettings.productName}
            onChange={(e) => handleFieldChange('oneProductSettings', e.target.value, 'productName')}
            onBlur={() => handleOfferUpdate({ upsellSettings: state })}
            fullWidth
          />
          <ImageInput
            label='Image URL'
            className={classes.textField}
            value={oneProductSettings.imgUrl}
            onChange={(e) => handleFieldChange('oneProductSettings', e.target.value, 'imgUrl')}
            onBlur={() => handleOfferUpdate({ upsellSettings: state })}
            fullWidth
          />
        </>
      );
    };

    return (
      <>
        <Typography variant='h6' gutterBottom style={{ marginBottom: '16px' }}>
          Upsell Modal Settings
        </Typography>
        <FormControl fullWidth className={classes.textField}>
          <InputLabel>Template</InputLabel>
          <Select
            value={state.template}
            onChange={(e) =>
              handleOfferUpdate({
                upsellSettings: { ...state, template: e.target.value },
              })
            }
          >
            <MenuItem value='freeProducts'>Free Products</MenuItem>
            <MenuItem value='oneProduct'>One Product</MenuItem>
          </Select>
        </FormControl>
        {state.template === 'oneProduct' && renderOneProductSettings()}

        <FormControlLabel
          className={classes.textField}
          control={
            <Checkbox
              checked={state.countdown}
              onChange={(e) =>
                handleOfferUpdate({
                  upsellSettings: { ...state, countdown: e.target.checked },
                })
              }
              name='countdown'
              color='primary'
            />
          }
          label='Countdown'
        />
        <TextField
          label='Title'
          className={classes.textField}
          value={state.title}
          onChange={(e) => handleFieldChange('title', e.target.value)}
          onBlur={() => handleOfferUpdate({ upsellSettings: state })}
          fullWidth
        />
        <TextField
          label='Subtitle'
          className={classes.textField}
          value={state.subtitle}
          onChange={(e) => handleFieldChange('subtitle', e.target.value)}
          onBlur={() => handleOfferUpdate({ upsellSettings: state })}
          fullWidth
        />
        <TextField
          label='Terms'
          multiline
          minRows={3}
          className={classes.textField}
          value={state.terms}
          onChange={(e) => handleFieldChange('terms', e.target.value)}
          onBlur={() => handleOfferUpdate({ upsellSettings: state })}
          fullWidth
        />

        <Typography variant='h6' gutterBottom style={{ marginTop: '20px' }}>
          Last Chance Page
        </Typography>
        <FormControlLabel
          className={classes.textField}
          control={
            <Checkbox
              checked={state.lastChancePage.countdown}
              onChange={(e) =>
                handleOfferUpdate({
                  upsellSettings: {
                    ...state,
                    lastChancePage: {
                      ...state.lastChancePage,
                      countdown: e.target.checked,
                    },
                  },
                })
              }
              name='countdownLastChance'
              color='primary'
            />
          }
          label='Countdown'
        />
        <TextField
          label='Title'
          className={classes.textField}
          value={state.lastChancePage.title}
          onChange={(e) => handleFieldChange('lastChancePage', e.target.value, 'title')}
          onBlur={() => handleOfferUpdate({ upsellSettings: state })}
          fullWidth
        />
        <TextField
          label='Subtitle'
          className={classes.textField}
          value={state.lastChancePage.subtitle}
          onChange={(e) => handleFieldChange('lastChancePage', e.target.value, 'subtitle')}
          onBlur={() => handleOfferUpdate({ upsellSettings: state })}
          fullWidth
        />
      </>
    );
  };

  const FbPixels = () => {
    const [fbPixels, setFbPixels] = useState([]);

    useEffect(() => {
      setFbPixels(offer.fbPixels);
    }, [offer]);

    function handleUpdatePixel(e, index) {
      const newFbPixels = [...fbPixels];
      newFbPixels[index][e.target.name] = e.target.value;
      setFbPixels(newFbPixels);
    }

    function handleAddFbPixel() {
      handleOfferUpdate({
        fbPixels: [
          ...fbPixels,
          {
            id: '',
            token: '',
            name: '',
            affid: '',
          },
        ],
      });
    }

    function handleDeleteFbPixel(id) {
      handleOfferUpdate({
        fbPixels: fbPixels.filter((item) => item.id !== id),
      });
    }

    if (!fbPixels.length) {
      return (
        <>
          <Typography variant='h6' gutterBottom>
            Facebook pixels
          </Typography>
          <IconButton
            size='small'
            color='primary'
            aria-label='Add'
            component='span'
            onClick={() => handleAddFbPixel()}
            className={classes.fbPixelIcon}
          >
            <AddIcon />
          </IconButton>
        </>
      );
    }

    return (
      <>
        <Typography variant='h6' gutterBottom>
          Facebook pixels
        </Typography>
        {fbPixels.map(({ id, token, name, affid }, index) => (
          <Box className={classes.fbPixelPair} key={index}>
            <TextField
              name='name'
              label='Account name'
              helperText='Optional'
              value={name}
              className={classes.fbPixelId}
              onChange={(e) => handleUpdatePixel(e, index)}
              onBlur={() =>
                handleOfferUpdate({
                  fbPixels,
                })
              }
            />
            <TextField
              name='affid'
              label='Affid'
              value={affid}
              className={classes.fbPixelId}
              onChange={(e) => handleUpdatePixel(e, index)}
              onBlur={() =>
                handleOfferUpdate({
                  fbPixels,
                })
              }
            />
            <TextField
              name='id'
              label='Source id'
              value={id}
              className={classes.fbPixelId}
              onChange={(e) => handleUpdatePixel(e, index)}
              onBlur={() =>
                handleOfferUpdate({
                  fbPixels,
                })
              }
            />
            <TextField
              name='token'
              label='Access token'
              value={token}
              multiline
              maxRows={4}
              className={classes.fbPixelToken}
              onChange={(e) => handleUpdatePixel(e, index)}
              onBlur={() =>
                handleOfferUpdate({
                  fbPixels,
                })
              }
            />
            <Box className={classes.fbPixelIcons}>
              <IconButton
                color='secondary'
                aria-label='delete pixel'
                component='span'
                size='small'
                className={classes.fbPixelIcon}
                onClick={() => handleDeleteFbPixel(id)}
              >
                <DeleteIcon />
              </IconButton>

              <IconButton
                size='small'
                color='primary'
                aria-label='Add'
                component='span'
                onClick={() => handleAddFbPixel()}
                className={classes.fbPixelIcon}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
      </>
    );
  };

  const TikTokPixels = () => {
    const [tikTokPixels, setTikTokPixels] = useState([]);

    useEffect(() => {
      setTikTokPixels(offer.tikTokPixels);
    }, [offer]);

    function handleUpdatePixel(e, index) {
      const newTikTokPixels = [...tikTokPixels];
      newTikTokPixels[index][e.target.name] = e.target.value;
      setTikTokPixels(newTikTokPixels);
    }

    function handleAddTikTokPixel() {
      handleOfferUpdate({
        tikTokPixels: [
          ...tikTokPixels,
          {
            id: '',
            token: '',
          },
        ],
      });
    }

    function handleDeleteTikTokPixel(id) {
      handleOfferUpdate({
        tikTokPixels: tikTokPixels.filter((item) => item.id !== id),
      });
    }

    if (!tikTokPixels.length) {
      return (
        <>
          <Typography variant='h6' gutterBottom>
            TikTok pixels
          </Typography>
          <IconButton
            size='small'
            color='primary'
            aria-label='Add'
            component='span'
            onClick={() => handleAddTikTokPixel()}
            className={classes.fbPixelIcon}
          >
            <AddIcon />
          </IconButton>
        </>
      );
    }

    return (
      <>
        <Typography variant='h6' gutterBottom>
          TikTok pixels
        </Typography>
        {tikTokPixels.map(({ id, token }, index) => (
          <Box className={classes.fbPixelPair} key={index}>
            <TextField
              name='id'
              label='Pixel id'
              value={id}
              className={classes.fbPixelId}
              onChange={(e) => handleUpdatePixel(e, index)}
              onBlur={() =>
                handleOfferUpdate({
                  tikTokPixels,
                })
              }
            />
            <TextField
              name='token'
              label='Access token'
              value={token}
              multiline
              maxRows={4}
              className={classes.fbPixelToken}
              onChange={(e) => handleUpdatePixel(e, index)}
              onBlur={() =>
                handleOfferUpdate({
                  tikTokPixels,
                })
              }
            />
            <Box className={classes.fbPixelIcons}>
              <IconButton
                color='secondary'
                aria-label='delete pixel'
                component='span'
                size='small'
                className={classes.fbPixelIcon}
                onClick={() => handleDeleteTikTokPixel(id)}
              >
                <DeleteIcon />
              </IconButton>

              <IconButton
                size='small'
                color='primary'
                aria-label='Add'
                component='span'
                onClick={() => handleAddTikTokPixel()}
                className={classes.fbPixelIcon}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
      </>
    );
  };

  function renderSpecialOffers() {
    function handleSpecialOffersChange(values) {
      const newValues = values.map((item) => item.id);

      handleOfferUpdate({
        specialOfferIds: newValues,
      });
    }

    const options = specialOffers.map((item) => ({
      id: item._id,
      name: `[${item.template.name}] - ${item.name} `,
    }));

    function getNameById(id) {
      const result = specialOffers.find((item) => item._id === id);
      return result ? `[${result.template.name}] - ${result.name}` : '';
    }

    return (
      <Box className={classes.specialOffers}>
        <MultiSelect
          label='Special offers'
          optionLabel='name'
          helperText='Note: Free Products always first'
          options={options}
          value={offer.specialOfferIds.map((id) => ({
            id,
            name: getNameById(id),
          }))}
          onChange={handleSpecialOffersChange}
        />
      </Box>
    );
  }

  function renderModalFreeProducts() {
    const options = freeProducts.reduce((acc, item) => {
      if (item.active === true) {
        acc.push({
          id: item._id,
          name: item.name,
        });
      }
      return acc;
    }, []);

    function handleChange(values) {
      handleOfferUpdate({
        freeProducts: values,
      });
    }

    return (
      <Box className={classes.freeProducts}>
        <MultiSelect
          label='Modal Free Products'
          optionLabel='name'
          options={options}
          value={offer.freeProducts.map(({ id, name }) => ({
            id,
            name,
          }))}
          onChange={handleChange}
        />
        <Typography variant='caption' gutterBottom style={{ color: '#858585', fontStyle: 'italic' }}>
          If empty - modal won't be shown.
        </Typography>
      </Box>
    );
  }

  function renderPageFreeProducts() {
    const options = freeProducts.reduce((acc, item) => {
      if (item.active === true) {
        acc.push({
          id: item._id,
          name: item.name,
        });
      }
      return acc;
    }, []);

    function handleChange(values) {
      handleOfferUpdate({
        freeProductsForPage: values,
      });
    }

    return (
      <Box className={classes.freeProducts}>
        <MultiSelect
          label='Page Free Products'
          optionLabel='name'
          options={options}
          value={offer.freeProductsForPage.map(({ id, name }) => ({
            id,
            name,
          }))}
          onChange={handleChange}
        />
        <Typography variant='caption' gutterBottom style={{ color: '#858585', fontStyle: 'italic' }}>
          If empty - modal free products will be shown.
        </Typography>
      </Box>
    );
  }

  function renderReviews() {
    function handleReviewsChange(values) {
      handleOfferUpdate({
        reviews: values,
      });
    }

    const options = reviews.map(({ _id, name, title, offer }) => ({
      id: _id,
      name: `[${offer.name}] ${title} ${name}`,
    }));

    return (
      <Box className={classes.reviews}>
        <MultiSelect
          label='Reviews'
          optionLabel='name'
          options={options}
          value={offer.reviews}
          onChange={handleReviewsChange}
        />
      </Box>
    );
  }

  function renderCrossSells() {
    function getItemById(id) {
      return crossSells.find((item) => item._id === id);
    }

    function handleCrossSellsChange(values) {
      const newCrossSells = [];

      values.forEach((item) => {
        newCrossSells.push(item);
      });

      handleOfferUpdate({
        crossSells: newCrossSells,
      });
    }

    const options = crossSells.map((item) => ({
      id: item._id,
      name: item.name,
    }));

    const value = offer.crossSells.map((item) => {
      const crossSell = getItemById(item._id || item.id);

      return {
        id: crossSell._id,
        name: crossSell.name,
      };
    });

    return (
      <Box className={classes.crossSells}>
        <MultiSelect
          label='Cross-sells'
          optionLabel='name'
          options={options}
          value={value}
          onChange={handleCrossSellsChange}
        />
      </Box>
    );
  }

  function renderDefaultVariantSelector() {
    function handleChange(e) {
      handleOfferUpdate({
        defaultVariantId: e.target.value,
      });
    }

    return (
      <Box className={classes.textField}>
        <FormControl fullWidth>
          <InputLabel>Default variant</InputLabel>
          <Select value={offer.defaultVariantId} onChange={handleChange}>
            {offer.productsData.map((variant) => (
              <MenuItem key={variant.id} value={variant.id}>
                {variant.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  }

  function renderTemplateSelector() {
    const templates = [
      {
        id: 1,
        name: 'Default',
      },
    ];

    function handleChange(e) {
      handleOfferUpdate({
        template: e.target.value,
      });
    }

    return (
      <Box className={classes.textField}>
        <FormControl fullWidth>
          <InputLabel>Checkout template</InputLabel>
          <Select value={offer.template} onChange={handleChange}>
            {templates.map((template) => (
              <MenuItem key={template.id} value={template.id}>
                {`[${template.id}] - ${template.name}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function renderShippingCountries() {
    const options = [
      {
        id: 'US',
        name: 'United States',
      },
      {
        id: 'CA',
        name: 'Canada',
      },
      {
        id: 'GB',
        name: 'United Kingdom',
      },
      {
        id: 'IE',
        name: 'Ireland',
      },
    ];

    function handleChange(values) {
      handleOfferUpdate({
        shippingCountries: values,
      });
    }

    return (
      <Box className={classes.freeProducts}>
        <MultiSelect
          label='Shipping Countries'
          optionLabel='name'
          options={options}
          value={offer.shippingCountries.map(({ id, name }) => ({
            id,
            name,
          }))}
          onChange={handleChange}
        />
      </Box>
    );
  }

  const OfferSettings = () => {
    const [name, setName] = useState('');

    useEffect(() => {
      setName(offer.name);
    }, [offer]);

    return (
      <TabPanel value={value} index={0}>
        <Paper className={classes.paper}>
          <TextField
            label='Offer id'
            value={offer._id}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            className={classes.textField}
          />
          <TextField
            label='Offer name'
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                name: e.target.value,
              })
            }
            fullWidth
            className={classes.textField}
          />

          <Box className={classes.localeSelect}>
            <FormControl
              className={classes.textField}
              style={{
                marginRight: '16px',
              }}
            >
              <InputLabel>Locale</InputLabel>
              <Select
                value={offer.locale}
                onChange={(e) =>
                  handleOfferUpdate({
                    locale: e.target.value,
                  })
                }
                fullWidth
              >
                <MenuItem value='en-US'>en-US</MenuItem>
                <MenuItem value='en-GB'>en-GB</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.textField}>
              <InputLabel>Currency</InputLabel>
              <Select
                value={offer.currency}
                onChange={(e) =>
                  handleOfferUpdate({
                    currency: e.target.value,
                  })
                }
                fullWidth
              >
                <MenuItem value='USD'>USD</MenuItem>
                <MenuItem value='GBP'>GBP</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {renderShippingCountries()}
          <ChipsList
            items={offer.productIds}
            onUpdate={(newItems) => handleOfferUpdate({ productIds: newItems })}
            title='Product Ids'
          />
          {offer.productIds.length > 0 && (
            <ProductsList
              offer={offer}
              updateFunction={updateOffer}
              onSyncClick={() => handleOfferUpdate({ productIds: offer.productIds })}
            />
          )}

          {renderCrossSells()}
          {renderModalFreeProducts()}
          {renderPageFreeProducts()}
          {renderSpecialOffers()}
          {renderReviews()}
          <CompanySettings />
        </Paper>
      </TabPanel>
    );
  };

  const CheckoutSettings = () => {
    const [logoSrc, setLogoSrc] = useState('');
    const [faviconSrc, setFaviconSrc] = useState('');
    const [brand, setBrand] = useState('');
    const [productImageSrc, setProductImageSrc] = useState('');
    const [productName, setProductName] = useState('');
    const [productNameSingular, setProductNameSingular] = useState('');
    const [payPalMerchantAccountId, setPayPalMerchantAccountId] = useState('');
    const [sizeGuideImage, setSizeGuideImage] = useState('');

    useEffect(() => {
      setLogoSrc(offer.logoSrc);
      setFaviconSrc(offer.faviconSrc);
      setBrand(offer.brand);
      setProductImageSrc(offer.productImageSrc);
      setProductName(offer.productName);
      setProductNameSingular(offer.productNameSingular);
      setPayPalMerchantAccountId(offer.payPalMerchantAccountId);
      setSizeGuideImage(offer.sizeGuideImage);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offer]);

    const BenefitsWithIcons = () => {
      const [benefits, setBenefits] = useState([
        {
          name: '',
          icon: '',
        },
      ]);

      useEffect(() => {
        setBenefits(offer.benefitsWithIcons);
      }, [offer]);

      function handleAddBenefitsItem() {
        handleOfferUpdate({
          benefitsWithIcons: [
            ...benefits,
            {
              name: '',
              icon: '',
            },
          ],
        });
      }

      function handleUpdateBenefitsItem(update, index) {
        const newBenefits = [...benefits];
        const entries = Object.entries(update)[0];

        newBenefits[index][entries[0]] = entries[1];
        setBenefits(newBenefits);
        handleOfferUpdate({ benefitsWithIcons: newBenefits });
      }

      function handleDeleteBenefitsItem(icon) {
        const newBenefits = benefits.filter((item, index) => item.icon !== icon);

        handleOfferUpdate({
          benefitsWithIcons:
            newBenefits.length > 0
              ? newBenefits
              : [
                  {
                    name: '',
                    icon: '',
                  },
                ],
        });
      }

      const BenefitsItem = ({ item, onUpdate, index }) => {
        const [name, setName] = useState(item.name);
        const [icon, setIcon] = useState(item.icon);

        return (
          <>
            <div style={{ flex: 1, marginRight: '8px' }}>
              <TextField
                label='Name'
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={() => onUpdate({ name }, index)}
                className={classes.textField}
                fullWidth
              />
            </div>
            <div style={{ flex: 2 }}>
              <ImageInput
                label='Icon'
                value={icon}
                onChange={(e) => setIcon(e.target.value)}
                onBlur={() => onUpdate({ icon }, index)}
                className={classes.textField}
                fullWidth
              />
            </div>
          </>
        );
      };

      return (
        <>
          <Typography variant='h6' gutterBottom>
            Benefits with icons
          </Typography>
          {benefits.map((item, index) => (
            <Box className={classes.products} key={index}>
              <BenefitsItem index={index} item={item} onUpdate={handleUpdateBenefitsItem} />
              <Box className={classes.iconsGroup}>
                <IconButton
                  color='secondary'
                  aria-label='Delete'
                  component='span'
                  size='small'
                  onClick={() => handleDeleteBenefitsItem(item.icon)}
                  className={classes.productsIcon}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  color='primary'
                  aria-label='Add'
                  component='span'
                  size='small'
                  onClick={() => handleAddBenefitsItem()}
                  className={classes.productsIcon}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
          {!benefits.length && (
            <Box className={classes.iconsGroup}>
              <IconButton
                color='primary'
                aria-label='Add'
                component='span'
                size='small'
                onClick={() => handleAddBenefitsItem()}
                className={classes.productsIcon}
              >
                <AddIcon />
              </IconButton>
            </Box>
          )}
        </>
      );
    };

    return (
      <TabPanel value={value} index={1}>
        <Paper className={classes.paper}>
          {renderTemplateSelector()}

          <ImageInput
            value={logoSrc}
            label='Logo image url'
            onChange={(e) => setLogoSrc(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                logoSrc: e.target.value,
              })
            }
          />
          <ImageInput
            label='Favicon source url'
            value={faviconSrc}
            onChange={(e) => setFaviconSrc(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                faviconSrc: e.target.value,
              })
            }
          />

          <ImageInput
            label='Product image url'
            value={productImageSrc}
            onChange={(e) => setProductImageSrc(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                productImageSrc: e.target.value,
              })
            }
          />

          <ImageInput
            label='Size Guide Image URL'
            value={sizeGuideImage}
            onChange={(e) => setSizeGuideImage(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                sizeGuideImage: e.target.value,
              })
            }
          />

          <TextField
            label='Singular Product name'
            value={productNameSingular}
            onChange={(e) => setProductNameSingular(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                productNameSingular: e.target.value,
              })
            }
            fullWidth
            className={classes.textField}
          />

          <TextField
            label='Plural Product name'
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                productName: e.target.value,
              })
            }
            fullWidth
            className={classes.textField}
          />

          <TextField
            label='Brand'
            helperText='For Thank You page'
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                brand: e.target.value,
              })
            }
            fullWidth
            className={classes.textField}
          />

          <FormControl className={classes.textField}>
            <InputLabel>Products sorting</InputLabel>
            <Select
              value={offer.productsSorting}
              onChange={(e) => {
                handleOfferUpdate({
                  productsSorting: e.target.value,
                });
              }}
            >
              <MenuItem value={'asc'}>Ascending</MenuItem>
              <MenuItem value={'desc'}>Descending</MenuItem>
            </Select>
          </FormControl>
          {renderDefaultVariantSelector()}

          <BenefitsWithIcons />

          <ChipsList
            items={offer.benefits}
            onUpdate={(newItems) => handleOfferUpdate({ benefits: newItems })}
            title='Payment Form Benefits'
          />

          <Typography variant='h6' gutterBottom>
            PayPal Settings
          </Typography>
          <FormControlLabel
            className={classes.textField}
            control={
              <Checkbox
                checked={offer.showPayPal}
                onChange={(e) => {
                  handleOfferUpdate({
                    showPayPal: e.target.checked,
                  });
                }}
                color='primary'
              />
            }
            label='PayPal Button Active'
          />
          <FormControlLabel
            className={classes.textField}
            control={
              <Checkbox
                checked={offer.showFreeProductsIfPayPal}
                onChange={(e) => {
                  handleOfferUpdate({
                    showFreeProductsIfPayPal: e.target.checked,
                  });
                }}
                color='primary'
              />
            }
            label='Show Free Products if PayPal order'
          />

          <TextField
            label='Merchant Account ID'
            helperText='for BrainTree'
            value={payPalMerchantAccountId}
            onChange={(e) => setPayPalMerchantAccountId(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                payPalMerchantAccountId: e.target.value,
              })
            }
            fullWidth
            className={classes.textField}
          />

          <PreloaderSettings />
          <UpsellSettings />
        </Paper>
      </TabPanel>
    );
  };

  const ExternalSettings = () => {
    const [gtmId, setGtmId] = useState('');
    const [gaId, setGaId] = useState('');
    const [gaMeasurementId, setGaMeasurementId] = useState('');
    const [gaApiSecret, setGaApiSecret] = useState('');
    const [campaignId, setCampaignId] = useState('');
    const [fallbackCampaignId, setFallbackCampaignId] = useState('');
    const [klaviyoProspectListId, setProspectList] = useState('');
    const [klaviyoCustomerListId, setCustomerList] = useState('');
    const [klaviyoSmsListId, setKlaviyoSmsListId] = useState('');
    const [klaviyoPrivateKey, setKlaviyoPrivateKey] = useState('');
    const [klaviyoPublicKey, setKlaviyoPublicKey] = useState('');

    useEffect(() => {
      setGtmId(offer.gtmId);
      setGaId(offer.gaId);
      setGaMeasurementId(offer.gaMeasurementId);
      setGaApiSecret(offer.gaApiSecret);
      setCampaignId(offer.campaignId);
      setFallbackCampaignId(offer.fallbackCampaignId);
      setProspectList(offer.klaviyoProspectListId);
      setCustomerList(offer.klaviyoCustomerListId);
      setKlaviyoSmsListId(offer.klaviyoSmsListId);
      setKlaviyoPrivateKey(offer.klaviyoPrivateKey);
      setKlaviyoPublicKey(offer.klaviyoPublicKey);
    }, [offer]);

    return (
      <TabPanel value={value} index={2}>
        <Paper className={classes.paper}>
          <TextField
            label='Google Tag Manager id'
            value={gtmId}
            onChange={(e) => setGtmId(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                gtmId: e.target.value,
              })
            }
            fullWidth
            className={classes.textField}
          />
          <TextField
            label='Google UA id'
            value={gaId}
            onChange={(e) => setGaId(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                gaId: e.target.value,
              })
            }
            fullWidth
            className={classes.textField}
          />
          <TextField
            label='GA4 Measurement Id'
            helperText='G-XXXXXXXXXX'
            value={gaMeasurementId}
            onChange={(e) => setGaMeasurementId(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                gaMeasurementId: e.target.value,
              })
            }
            fullWidth
            className={classes.textField}
          />
          <TextField
            label='GA4 API Secret'
            helperText='GA Admin->Data Streams->Details->Measurement Protocol API secrets'
            value={gaApiSecret}
            onChange={(e) => setGaApiSecret(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                gaApiSecret: e.target.value,
              })
            }
            fullWidth
            className={classes.textField}
          />
          <TextField
            label='Sticky campaign id'
            value={campaignId}
            onChange={(e) => setCampaignId(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                campaignId: e.target.value,
              })
            }
            fullWidth
            className={classes.textField}
          />
          <TextField
            label='Sticky fallback campaign id'
            value={fallbackCampaignId}
            onChange={(e) => setFallbackCampaignId(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                fallbackCampaignId: e.target.value,
              })
            }
            fullWidth
            className={classes.textField}
          />

          <TextField
            label='Klaviyo public key'
            value={klaviyoPublicKey}
            onChange={(e) => setKlaviyoPublicKey(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                klaviyoPublicKey: e.target.value,
              })
            }
            fullWidth
            className={classes.textField}
          />
          <TextField
            label='Klaviyo private key'
            value={klaviyoPrivateKey}
            onChange={(e) => setKlaviyoPrivateKey(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                klaviyoPrivateKey: e.target.value,
              })
            }
            fullWidth
            className={classes.textField}
          />
          <TextField
            label='Klaviyo prospect list id'
            value={klaviyoProspectListId}
            onChange={(e) => setProspectList(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                klaviyoProspectListId: e.target.value,
              })
            }
            fullWidth
            className={classes.textField}
          />
          <TextField
            label='Klaviyo customer list id'
            value={klaviyoCustomerListId}
            onChange={(e) => setCustomerList(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                klaviyoCustomerListId: e.target.value,
              })
            }
            fullWidth
            className={classes.textField}
          />

          <TextField
            label='Klaviyo SMS list id'
            value={klaviyoSmsListId}
            onChange={(e) => setKlaviyoSmsListId(e.target.value)}
            onBlur={(e) =>
              handleOfferUpdate({
                klaviyoSmsListId: e.target.value,
              })
            }
            fullWidth
            className={classes.textField}
          />

          <FbPixels />
          <TikTokPixels />
        </Paper>
      </TabPanel>
    );
  };

  function renderPageActionButtons() {
    async function onCloneClick() {
      const clone = offer;
      delete clone.createdAt;
      delete clone._id;
      delete clone.__v;
      clone.name += ' CLONE';

      try {
        const created = await createOffer(clone);
        history.push({});
        history.push(`/offers/${created._id}`);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error: ', error);
      }
    }

    return (
      <Box className={classes.pageActionButtons}>
        <Button
          variant='contained'
          color='primary'
          endIcon={<ContentCopyIcon />}
          onClick={() => onCloneClick()}
        >
          Clone
        </Button>
      </Box>
    );
  }

  return (
    <>
      {offerLoading && <LinearProgress className={classes.progress} />}
      <Container maxWidth='lg' className={classes.container}>
        {renderPageActionButtons()}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label='Offer Settings' {...a11yProps(0)} />
            <Tab label='Checkout Page Settings' {...a11yProps(1)} />
            <Tab label='External Settings' {...a11yProps(2)} />
          </Tabs>
        </Box>
        <OfferSettings />
        <CheckoutSettings />
        <ExternalSettings />
      </Container>
    </>
  );
}

OfferPage.propTypes = {
  onRender: PropTypes.func,
};
