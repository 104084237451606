import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Chip, makeStyles, Tooltip } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  filterInputs: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  input: {
    width: '200px',
  },
  filtersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CustomFilters = ({ onFilterChange, customFilters = {} }) => {
  const classes = useStyles();
  const [paramName, setParamName] = useState('');
  const [paramValue, setParamValue] = useState('');

  const handleAddFilter = () => {
    if (paramName.trim() === '' || paramValue.trim() === '') return;

    // Update filters in parent component
    const newFilters = {
      ...customFilters,
      [paramName]: paramValue,
    };

    onFilterChange('customFilters', newFilters);

    // Reset inputs
    setParamName('');
    setParamValue('');
  };

  const handleDeleteFilter = (filterName) => {
    const newFilters = { ...customFilters };
    delete newFilters[filterName];

    onFilterChange('customFilters', newFilters);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Typography variant='subtitle1'>Custom Filters</Typography>
        <Tooltip title="These filters apply only to orders, not analytics. You can use dot notation for nested fields (e.g. 'processedResponseData.order_id')">
          <HelpOutlineIcon className={classes.helpIcon} fontSize='small' sx={{ ml: 1 }} />
        </Tooltip>
      </Box>

      <Box className={classes.filterInputs}>
        <TextField
          label='Parameter Name'
          variant='outlined'
          size='small'
          className={classes.input}
          value={paramName}
          onChange={(e) => setParamName(e.target.value)}
          placeholder='e.g. email or processedResponseData.order_id'
        />
        <TextField
          label='Parameter Value'
          variant='outlined'
          size='small'
          className={classes.input}
          value={paramValue}
          onChange={(e) => setParamValue(e.target.value)}
        />
        <Button
          variant='contained'
          color='primary'
          startIcon={<AddIcon />}
          onClick={handleAddFilter}
          disabled={!paramName || !paramValue}
        >
          Add
        </Button>
      </Box>

      {Object.keys(customFilters).length > 0 && (
        <Box className={classes.filtersContainer}>
          <Typography variant='body2' style={{ marginRight: '8px' }}>
            Active filters:
          </Typography>
          {Object.entries(customFilters).map(([name, value], index) => (
            <Chip
              key={index}
              label={`${name}: ${value}`}
              onDelete={() => handleDeleteFilter(name)}
              className={classes.chip}
              color='primary'
              variant='outlined'
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CustomFilters;
